.workspace {
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    font: 700 12px system-ui;
  }
  